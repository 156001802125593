@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-50;
  }
  h1 {
    @apply mb-4 text-3xl font-semibold text-slate-800;
  }
  h2 {
    @apply mb-2 text-2xl font-semibold text-slate-800;
  }
  h3 {
    @apply mb-2 text-xl font-semibold text-orange-600;
  }
  p {
    @apply mb-4;
  }
  a {
    @apply border-b border-blue-500 text-blue-500 hover:text-blue-700;
  }
  table {
    @apply my-8 border-collapse border border-gray-300 shadow-md;
  }
  tr {
    @apply border-b border-gray-200 even:bg-gray-100;
  }
  th {
    @apply border-r border-gray-200 py-2 px-4 text-center text-slate-800;
  }
  td {
    @apply border-r border-gray-200 p-2 text-center;
  }
  code {
    @apply bg-gray-200 px-2;
  }
  pre code {
    @apply bg-gray-200 p-0;
  }
  pre {
    @apply my-8 whitespace-pre-wrap bg-gray-200 p-4;
  }
  ul {
    @apply my-6 list-inside list-disc;
  }
  ul ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-left: 3em;
  }
  b {
    @apply text-slate-800;
  }
}

@layer components {
  .btn {
    @apply rounded-md border-0 py-2 px-4 font-semibold focus:outline-none focus:ring-2 focus:ring-opacity-70 disabled:cursor-not-allowed disabled:opacity-50;
  }
  .btn-secondary {
    @apply border-gray-300 bg-gray-100  hover:bg-gray-200 hover:text-black  focus:ring-gray-300;
  }
  .btn-primary {
    @apply bg-orange-600 text-white shadow-md hover:bg-orange-700 hover:text-white  focus:ring-yellow-700;
  }
  .btn-sm {
    @apply ml-2 border border-gray-300 bg-gray-50 py-1 px-2 text-xs text-black hover:bg-gray-100 hover:text-black;
  }

  .nav-link {
    @apply mr-2 block cursor-pointer rounded border-0 bg-orange-600 px-2 py-1 font-bold tracking-tight text-white hover:bg-orange-700 hover:text-white md:mr-0 md:bg-white  md:text-inherit md:hover:bg-white md:hover:bg-white md:hover:text-orange-500;
  }
  .card-container {
    @apply mb-4 flex flex-col flex-wrap items-center justify-center md:flex-row md:items-stretch;
  }
  .card-350 {
    @apply m-4 max-w-[350px] rounded border border-slate-300 p-4 text-center;
  }
  .form-field-stripe {
    @apply mb-2 rounded border border-slate-500 bg-white p-2;
  }
  .form-field {
    @apply rounded border border-slate-500 p-1 pl-2 focus:outline-none;
  }
  .orange {
    @apply text-orange-600;
  }
  .responsive-video {
    height: 0;
    padding-top: 1px;
    position: relative;
    margin-bottom: -3px;
    padding-bottom: 56.25%;
  }
  .responsive-video iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .starOn {
    @apply text-orange-600;
  }
  .starOff {
    @apply text-gray-300;
  }
}
